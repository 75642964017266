import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, signal } from '@angular/core';
import { LARGE, MEDIUM, SMALL } from '../../scss/responsive/responsive';

@Injectable({
  providedIn: 'root',
})
export class ResolutionService {
  isMobile = signal(false);
  isGraterThanMedium = signal(false);
  isSmallMobile = signal(false);

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile.set(window.innerWidth <= LARGE);
      this.isGraterThanMedium.set(window.innerWidth >= MEDIUM);
      this.isSmallMobile.set(window.innerWidth <= SMALL);
      this.onResize();
    }
  }

  onResize() {
    window.addEventListener('resize', () => {
      this.isMobile.set(window.innerWidth <= LARGE);
      this.isSmallMobile.set(window.innerWidth <= SMALL);
      this.isGraterThanMedium.set(window.innerWidth >= MEDIUM);
    });
  }
}